import "@mdi/font/css/materialdesignicons.min.css";
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

export default createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'md',
  },
})