import { defineStore } from "pinia";
import axios from "axios";
import { get } from "lodash";

export const authStore = defineStore({
    id: "auth",
    state: () => ({
        user: null,
        isAuthenticated: localStorage.getItem("token") ? true : false,
        privileges: []
    }),
    mutations: {},
    actions: {
        setUser(user = null) {
            this.user = user;
        },
        async login(credentials) {
            try {
                const response = await axios.post("auth/login", credentials);
                if (response) {
                    const data = get(response, 'data.data', null);
                    const token = `${data.token_type} ${data.access_token}`;
                    localStorage.setItem("token", token);
                    axios.defaults.headers.common["Authorization"] = token;
                    this.$state.user = data.data;
                    const privileges = get(data, 'data.role.list_privileges', []);
                    this.$state.privileges = privileges;
                    this.$state.isAuthenticated = true;
                }
                return response.data;
            } catch (error) {
                return error.response.data;
            }
        },
        async getProfile() {
            try {
                const response = await axios.get("auth/show");
                if (response) {
                    const data = get(response, 'data.data', null);
                    const privileges = get(data, 'role.list_privileges', []);
                    this.$state.privileges = privileges;
                    this.$state.user = data;
                }
                return response.data;
            } catch (error) {
                return error.response.data;
            }
        },
        async logout() {
            localStorage.removeItem("token");
            this.$state.auth = null;
            this.$state.isAuthenticated = false;
        },
    },
    getters: {
        getterIsAuthenticated: (state) => state.isAuthenticated,
        getterUserAuth: (state) => state.user,
        getterPrivileges: (state) => state.privileges,
    },
    persist: {
        enabled: true,
        strategies: [
            { storage: localStorage, paths: ["user", "isAuthenticated"] },
        ],
    },
});