import axios from "axios";
import routerAdmin from '@/router/index';
import { authStore } from "@/stores/auth";

axios.defaults.baseURL = process.env.VUE_APP_API;
// @ts-ignore
axios.defaults.headers.common["Authorization"] = localStorage.getItem("token");
axios.defaults.headers.common["Accept"] = "application/json";

axios.interceptors.request.use(
    function (config) {
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        const store = authStore();
        if (error.response) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem("token");
                    store.$patch({
                        isAuthenticated: false,
                        user: null,
                    });
                    if (routerAdmin.currentRoute.value.name !== "admin.login") {
                        routerAdmin.push("/login");
                    }
                    break;
                case 404:
                    routerAdmin.replace("/404");
                    break;
                case 500:
                    routerAdmin.replace("/500");
                    break;
                default:
                    // console.log(error.response.data);
            }
        }

        return Promise.reject(error);
    }
);

export default axios;