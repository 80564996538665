<template lang="">
  <router-view v-if="isLoaded"></router-view>
</template>
<script>
import { regionStore } from "@/stores/region";
import { onMounted, ref } from "vue";
import { authStore } from "./stores/auth";
export default {
    setup() {
        const isLoaded = ref(false);
        const store = regionStore();
        const auth = authStore();
        onMounted(async () => {
            await store.fetchRegions({ page: 1 });
            await auth.getProfile();
            isLoaded.value = true;
        });

        return {
            isLoaded
        }
    }
}
</script>
<style lang="">
  
</style>