import App from '@/App.vue'
import router from '@/router'
import { createApp } from 'vue'
import axios from "@/services/axios";
import VueAxios from "vue-axios";
import VCalendar from 'v-calendar';

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import 'v-calendar/style.css';

const options = {
    // You can set your default options here
};

// Vuetify
import 'vuetify/styles'
import vuetify from '@/plugins/vuetify'
import VueWait from '@/plugins/vue-wait'
import pinia from '@/plugins/pinia'

createApp(App)
.use(VueWait)
.use(vuetify)
.use(pinia)
.use(Toast, options)
.use(VueAxios, axios)
.use(router)
.use(VCalendar, {})
.mount('#app')
