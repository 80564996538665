import { defineStore } from "pinia";
import axios from "axios";
import { get } from "lodash";

export const regionStore = defineStore({
    id: "region",
    state: () => ({
        regions: [],
    }),
    mutations: {},
    actions: {
        async fetchRegions() {
            try {
                const response = (await axios.get('regions'));
                if (response.data) {
                    this.regions = get(response, 'data.data', []);
                    return response;
                }
                return response;
            } catch (error) {
                console.log(error);
            }
        }
    },
    getters: {
        gettersListRegions: (state) => state.regions,
    }
});