import { createRouter, createWebHistory } from "vue-router";
import { authStore } from "@/stores/auth";   

const routes =  [
    {
        path: "/admin",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: '/clients',
        component: () => import("@/views/clients/Client.vue"),
        children : [
            {
                path: '',
                name: 'admin.clients',
                component: () => import("@/views/clients/ClientViews.vue")
            },
            {
                path: ':id/show',
                name: 'admin.clients.show',
                component: () => import("@/views/clients/ClientShow.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/roles',
        component: () => import("@/views/roles/Role.vue"),
        children : [
            {
                path: '',
                name: 'admin.roles',
                component: () => import("@/views/roles/RoleViews.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/users',
        component: () => import("@/views/users/User.vue"),
        children : [
            {
                path: '',
                name: 'admin.users',
                component: () => import("@/views/users/UserViews.vue")
            },
            {
                path: ':id/show',
                name: 'admin.users.show',
                component: () => import("@/views/users/UserView.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/voucher',
        component: () => import("@/views/vouchers/Voucher.vue"),
        children : [
            {
                path: '',
                name: 'admin.voucher',
                component: () => import("@/views/vouchers/VoucherViews.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/packages',
        component: () => import("@/views/packages/Package.vue"),
        children : [
            {
                path: '',
                name: 'admin.packages',
                component: () => import("@/views/packages/PackageViews.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/bookings',
        component: () => import("@/views/bookings/Booking.vue"),
        children : [
            {
                path: '',
                name: 'admin.bookings',
                component: () => import("@/views/bookings/BookingViews.vue")
            },
            {
                path: 'create',
                name: 'admin.booking.create',
                component: () => import("@/views/bookings/BookingCreate.vue")
            },
            {
                path: ':id/show',
                name: 'admin.booking.show',
                component: () => import("@/views/bookings/BookingView.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/blogs',
        component: () => import("@/views/blogs/Blog.vue"),
        children : [
            {
                path: '',
                name: 'admin.blogs',
                component: () => import("@/views/blogs/BlogViews.vue")
            },
            {
                path: 'create',
                name: 'admin.blogs.create',
                component: () => import("@/views/blogs/BlogCreate.vue")
            },
            {
                path: ':id/edit',
                name: 'admin.blogs.edit',
                component: () => import("@/views/blogs/BlogEdit.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/schools',
        component: () => import("@/views/schools/School.vue"),
        children : [
            {
                path: '',
                name: 'admin.school',
                component: () => import("@/views/schools/SchoolViews.vue")
            }
        ],
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/login',
        name: "admin.login",
        component: () => import("@/views/auth/Login.vue")
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to) => {
    if (to.path == '/') {
        return "/admin";
    }
    const auth = authStore();
    if (to.meta.requiresAuth === true && ! auth.isAuthenticated) return "/login";
});

export default router;